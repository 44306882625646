<template>
  <div id="swap0xgen">
    <iframe src="https://app.sypool.io/widget?domin=app.sypool.io"></iframe>
  </div>
</template>

<script>
export default {
  name: "swap0xGen",
};
</script>

<style lang="less" scoped>
#swap0xgen {
  text-align: center;
  iframe {
    width: 420px;
    height: 519px;
    border: none;
    border-radius: 33px;
    margin: 150px auto;
  }
}
</style>
